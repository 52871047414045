const NavMenu = ({ menuItems }) => {
    return (
      <nav>
        <ul>
          {menuItems.map((item) => (
            <li key={item.id}>
              <a href={item.link}>{item.label}</a>
          </li>
          ))}
        </ul>
      </nav>
    );
  };

  export default NavMenu;