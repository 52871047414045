import './App.scss';
import { menuItems } from  './components/NavItems.js';
import NavMenu from './components/NavMenu.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import History from "./pages/History.js";
import Home from "./pages/HomePage.js";
import Trends from "./pages/Trends.js";

function App() {
  return (
    <div className="App">
      <Router>
        <NavMenu menuItems={menuItems} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/trends" element={<Trends />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
