import axios from 'axios';
import { imgdata } from  '../components/image';


// Function to make a POST request and set base64 image as src
async function fetchBase64Image() {
    try {
      // Axios POST request
      const response = await axios.post('http://api-dynamic.gunpowderandsteel.com/image',
        {
            // You can pass additional data here if required by the API
            symbol: 'all', // Example parameter, adjust as per your API requirements
        }, {
        responseType: 'json'  // Expecting a JSON response with the base64 encoded image
      });



	console.log(response.data);
      // Assuming the base64 string is in response.data.imageBase64
      const base64Image = response.data.imageBase64; // Adjust according to your API response structure
  
      // Set the base64 image to the img element's src
      const imgElement = document.getElementById('myImage'); // Replace with your actual img element ID
      imgElement.src = `data:image/png;base64,${base64Image}`; // Adjust MIME type as needed
  
    } catch (error) {
      console.error('Error fetching the base64 image:', error);
    }
  }
  
  // Example usage
fetchBase64Image();



function History() {
    return (
        <div className="History">
            History
         	graph   
            <img alt="histimg" id='myImage' src={`data:image/jpeg;base64,${imgdata}`} />
        </div>
    );
}
export default History;
